import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/_global/layout/Layout";
import PageHeader from "../components/_global/layout/PageHeader";
import localize from "../components/_global/localization/localize";
import ItemList from "../components/_global/lists/ItemList";
import Section from "../components/_global/layout/Section";
import Spacer from "../components/_global/layout/Spacer";
import Conditional from "../components/_global/layout/Conditional";
import Years from "../components/6_agenda/Years";
import { useLanguageContext } from "../contexts/LanguageContext";

function sortBy(field) {
	return function (a, b) {
		if (a[field] > b[field]) {
			return 1;
		} else if (a[field] < b[field]) {
			return -1;
		}
		return 0;
	};
}

const Workshops = ({ data, ...props }) => {
	const { workshops, general, events: { nodes: events } } = data;
	const language = useLanguageContext();
	const dateFormatter = new Intl.DateTimeFormat(
		language.lang, { month: "long" });

	let filteredEvents = [];
	for (let i = 0; i < events.length; i++) {
		const eventDate = new Date(events[i].date);
		const monthNumber = eventDate.getMonth();
		const month = dateFormatter.format(eventDate);
		const year = eventDate.getFullYear();

		// if the year does not exists
		if (!filteredEvents.find(item => item.year === year)) {
			// create the year array
			filteredEvents.push({
				year: year,
				items: []
			});
		}

		const yearObject = filteredEvents.find(i => i.year === year);
		// if the month does not exists
		if (!yearObject.items.find(item => item.number === monthNumber)) {
			// create the month array
			filteredEvents.find(item => item.year === year).items.push({
				title: month,
				number: monthNumber,
				items: []
			});
		}

		// push to the month array
		yearObject.items.find(item => item.number === monthNumber).items.push(events[i]);
	}

	filteredEvents.sort(sortBy("year")).reverse();
	for (let i = 0; i < filteredEvents.length; i++) {
		filteredEvents[i].items.sort(sortBy("number")).reverse();
	}
	const [year, setYear] = useState(filteredEvents[0].year);

	const currentData = filteredEvents.find(i => i.year === year).items;
	const years = filteredEvents.map(i => i.year);

	return (
		<Layout title={workshops.common.title}>
			<PageHeader title={workshops.common.title} />
			<Section m="small" style={{ position: "sticky", top: "133px", backgroundColor: "white", zIndex: 10 }}>
				<Years data={currentData} year={year} years={years} setYear={setYear} />
			</Section>

			<Conditional desktop>
				<Spacer s="large" />
			</Conditional>

			<Conditional mobile>
				<Spacer s="medium" />
			</Conditional>

			<ItemList data={currentData} more={general.learnMore} baseLink="workshops" />
		</Layout>
	);
};

export default localize(Workshops);

export const query = graphql`
    {
        workshops: sanityWorkshops {
            common {
                link
                title {
                    _type
                    fr
                }
            }
        }
        general: sanityGeneralComponent {
            learnMore {
                _type
                fr
            }
        }
        events: allSanityWorkshop(sort: {fields: date, order: DESC}) {
            nodes {
                content {
                    ...CompleteBlockFragment
                }
                dateType,
                date(locale: "FR")
                description {
                    _type
                    fr
                }
                image {
                    ...ImageFragment
                }
                slug {
                    current
                }
                title {
                    _type
                    fr
                }
            }
        }
    }
`;
